<template>
  <div class="page-main">
    <el-form @submit.native.prevent="searchListData">
      <!-- 表格操作条 -->
      <vxe-toolbar ref="refToolbar" size="mini" export print custom :loading="loading">
        <template #buttons>
          <el-button
            type="success"
            size="mini"
            @click="($refs.Account.isTable = true), $refs.Account.addAccount()"
            >新增账户
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="
              ($refs.Account.isTable = true),
                ($refs.Account.accountForm.nickNameList = ''),
                ($refs.Account.dialogBatch = true),
                ($refs.Account.dialogBatchUpdate = false),
                ($refs.Account.accountForm.isBatchSaveType = true)
            "
            >批量新增
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="($refs.Account.isTable = true), $refs.Account.doBatchUpdteEvent()"
            >批量编辑角色
          </el-button>
        </template>
        <template #tools>
          <el-input
            style="width: 180px"
            size="mini"
            placeholder="请输入关键字"
            v-model="keyword"
            clearable
          />
          <el-button class="m-l-10" type="primary" size="mini" @click="searchListData">
            搜索
          </el-button>
        </template>
      </vxe-toolbar>
    </el-form>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      :loading="loading"
      :max-height="this.$util.getViewHeight() - 150"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :data="tableDataMain"
      :columns="tableColumn"
    >
      <template #default_operate="{ row }">
        <template>
          <vxe-button
            icon="el-icon-edit-outline"
            title="编辑"
            circle
            @click="($refs.Account.isTable = true), $refs.Account.editRowEvent(row.id)"
          />
        </template>
        <vxe-button icon="el-icon-delete" title="删除" circle @click="itemDelete(row)" />
      </template>
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>
    <Account ref="Account"></Account>
  </div>
</template>

<script>
import Account from '@/components/old/account'
export default {
  components: { Account },
  data() {
    return {
      loading: true,

      keyword: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      tableColumn: [
        { fixed: 'left', type: 'checkbox', width: 40 },
        { field: 'nick_name', title: '姓名' },
        { field: 'user_name', title: '登录账号(手机号码)' },
        { field: 'job', title: '职务' },
        { field: 'created', title: '创建日期' },
        { field: 'updated', title: '更新日期' },
        { field: 'role_name', title: '角色' },
        { field: 'commission_rate', title: '提成比例' },
        { field: 'over_date_str', title: '可调休时间', visible: false },
        {
          field: 'state',
          title: '启用',
          cellRender: {
            name: 'ElSwitch',
            props: { 'active-value': 1, 'inactive-value': 0 },
            events: { change: this.changeSwitch }
          }
        },
        { title: '操作', width: 120, slots: { default: 'default_operate' } }
      ],
      tableDataMain: [],
      pageOptions: {
        ...this.$constant.page
      }
    }
  },
  created() {
    this.initColumn()
    this.getListData()
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联
      this.$refs.refTable.connect(this.$refs.refToolbar)
    })
  },
  methods: {
    // 初始化列
    initColumn() {
      if (this.$tool.hasControl(['/dingding/saveOrUpdate'])) {
        this.tableColumn.forEach((item) => {
          if (item.field === 'over_date_str') {
            item.visible = true
          }
        })
      }
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/account/getAccountList',
        data: {
          keyword: this.keyword,
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize
        }
      })
        .then((res) => {
          if (res.status === 200) {
            this.tableDataMain = res.data.page.list
            this.pageOptions.pageSize = res.data.page.pageSize
            this.pageOptions.total = res.data.page.totalRow
            this.pageOptions.currentPage = res.data.page.pageNumber
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    itemDelete(row) {
      this.$XModal.confirm('您确定要删除"' + row.nick_name + '"该数据?').then((type) => {
        if (type === 'confirm') {
          // 传送删除动作
          this.$api({
            method: 'post',
            url: '/admin/account/deleteVue',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    changeSwitch({ row }, $event) {
      row.state = row.state == 0 ? 1 : 0
      this.$confirm('确定要改变账户状态 : ' + row.nick_name + '？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 发送ajax请求，处理
          this.$api({
            method: 'post',
            url: '/admin/account/switchStateVue',
            params: {
              id: row.id,
              checked: $event == 1 ? true : false
            }
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('编辑成功')
              row.state = row.state == 0 ? 1 : 0
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消操作')
        })
    },
    changeCompanyNameSwitch({ row }, $event) {
      row.is_show_company_name = row.is_show_company_name == true ? false : true
      this.$confirm('编辑显示客户名: ' + row.nick_name + '？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 发送ajax请求，处理
          this.$api({
            method: 'post',
            url: '/admin/account/changeCompanyNameSwitch',
            params: {
              id: row.id,
              checked: $event == true ? true : false
            }
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('编辑成功')
              row.is_show_company_name = row.is_show_company_name == true ? false : true
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消操作')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
